import React, { Component } from 'react';
import { $glVars } from '../common/common';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Form, FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import { FeedbackCtrl } from '../libs/components/Feedback';
import { Pagination } from '../libs/components/Pagination';
import { ToggleButtons } from '../libs/components/ToggleButtons';

export class SearchView extends Component
{
    static defaultProps = {
    };

    constructor(props){
        super(props);

        this.onDataChange = this.onDataChange.bind(this);
        this.getData = this.getData.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onChangePage = this.onChangePage.bind(this);

        this.state = {
            searchParam: {queryStr: "", language: $glVars.i18n.lang},
            searchResult: null, 
            pagination: {currentPage: 1, count: 0, itemPerPage: 25}
        };
    }

    onKeyDown(event) {
        if (event.which === 13 /* Enter */) {
            this.getData();
            event.preventDefault();
        }
    }

    getData(){
        let that = this;

        let callback = function(result){
            if(result.success){
                let pagination = that.state.pagination;
                pagination.count = result.data.data.totalCount;

                that.setState({
                    pagination: pagination,
                    searchResult: result.data.data.items, 
                });
            }
            else{
                FeedbackCtrl.instance.showError($glVars.i18n.tags.appName, result.msg);
            }
        }

        $glVars.webApi.searchOnBooks(this.state.pagination.itemPerPage, this.state.pagination.currentPage -1, this.state.searchParam, callback);
    }

    render() {
        let main = 
            <div className='p-3 container'>
                <h2>{$glVars.i18n.tags.search}</h2>
                <Form className='mb-3 mt-4'>
                    <FormGroup className='mb-2' onKeyDown={this.onKeyDown}>
                        <InputGroup>
                            <FormControl name="queryStr" type="text" placeholder={$glVars.i18n.tags.search+"..."} onChange={this.onDataChange} value={this.state.searchParam.queryStr}  aria-describedby="inputGroupPrepend" />
                            <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon icon={faSearch}/></InputGroup.Text>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <ToggleButtons name="language" defaultValue={[this.state.searchParam.language]} type="radio" onChange={this.onDataChange} 
                                        options={[{value: 'es', text: $glVars.i18n.tags.es}, 
                                        {value: 'fr', text: $glVars.i18n.tags.fr},
                                        {value: 'en', text: $glVars.i18n.tags.en}]}/>  
                    </FormGroup>
                </Form>
                
                {this.state.searchResult !== null && this.state.searchResult.length === 0 &&
                        <Alert variant='warning'>{$glVars.i18n.tags.noResult}</Alert>}
                
                {this.state.searchResult !== null && this.state.searchResult.length > 0 &&
                        <div className='d-flex flex-wrap mt-5 justify-content-sm-start justify-content-center'>
                            <div className='textMuted mb-5'>{$glVars.i18n.tags.searchResult.replace('$COUNT', this.state.pagination.count).replace('$ITEMS',this.state.pagination.itemPerPage)}</div>
                            
                            {this.state.searchResult.map((item, index) => {  
                                return <SearchResultItem key={index} dataProvider={item} searchParam={this.state.searchParam}/>
                            })}
                            <Pagination pagination={this.state.pagination} onChangePage={this.onChangePage}/>
                        </div>
                }
            </div>;
            
        return main;
    }

    onDataChange(event){
        let searchParam = this.state.searchParam;
        searchParam[event.target.name] = event.target.value;
        this.setState({searchParam: searchParam});
    }

    onChangePage(page){
        let pagination = this.state.pagination;
        pagination.currentPage = page;
        this.setState({pagination: pagination}, this.getData);
    }
}


class SearchResultItem extends Component{
    static defaultProps = {
        dataProvider: [],
        searchParam: ''
    };

    constructor(props){
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    render(){    
        if(this.props.dataProvider.length === 0)  { return null;}

        let chunk = this.props.dataProvider[0];

        let main = 
            <div>
                <h5 className='m-0'><b>{`${chunk.title}`}</b></h5>
                <div className='mb-2' style={{fontSize: 'small'}}>{` by ${$glVars.i18n.tags[chunk.author]}`}</div>
                {this.props.dataProvider.map((item, index) => {  
                    let chunk = item.bookContent;
                    if(this.props.searchParam.queryStr.length > 0){
                        let re = new RegExp(this.props.searchParam.queryStr, "i");
                        chunk = chunk.replace(re, `<b>$&</b>`);
                    }
                    return <div key={index}  className='border rounded mb-3 p-2' dangerouslySetInnerHTML={{__html: chunk}}></div>
                })}
            </div>;

        return (main);
    }
    
    onClick(){
        this.setState({showModal: !this.state.showModal});
    }
}