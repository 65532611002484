
import React, { Component } from 'react';
import {$glVars } from '../common/common';
import { Accordion, Button } from 'react-bootstrap';
import {   faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Options } from '../common/Options';
import { InputNumber } from '../libs/components/InputNumber';
import { PayPalButtons, PayPalScriptProvider  } from "@paypal/react-paypal-js";
//import './Checkout.css';

export class CheckoutOrderView extends Component{
    static defaultProps = {
    };

    constructor(props){
        super(props);

        this.getData = this.getData.bind(this);
        this.onProceedCheckout = this.onProceedCheckout.bind(this);
        this.onSeeMyCart = this.onSeeMyCart.bind(this);
        this.onApproveOrder = this.onApproveOrder.bind(this);

        this.state = {step: 0, data: null}
    }

    componentDidMount(){
        $glVars.webApi.addObserver("CheckoutOrderView", this.getData, ['removeFromCart']);

        this.getData();
    }

    componentWillUnmount(){
        $glVars.webApi.removeObserver("CheckoutOrderView");
    }

    getData(){
        let that = this;
        let callback = function(result){
            if(result.success){
                that.setState({data: result.data});
            }
        };

        $glVars.webApi.getMyCart(callback);
    }

    render(){
        let main = null;
        
        if(this.state.step === 0){
            main = <MyCartView data={this.state.data} onProceedCheckout={this.onProceedCheckout} />;
        }
        else if(this.state.step === 1){
            main = <PaymentOrderView data={this.state.data} onSeeMyCart={this.onSeeMyCart} onApproveOrder={this.onApproveOrder} />;
        }

        return main;
    }

    onSeeMyCart(){
        this.setState({step: 0});
    }

    onProceedCheckout(){
        this.setState({step: 1});
    }

    onApproveOrder(details){
        if(details.status.toLowerCase() !== 'completed'){
            $glVars.feedback.showWarning($glVars.i18n.tags.operationResultTitle, $glVars.i18n.tags.errorOccurred, 3);
            return;
        }

        $glVars.webApi.closeOrder(this.state.data.orderId, details, (result) => {
            if(result.success){
                $glVars.signedUser.myCart = null;
                $glVars.feedback.showInfo($glVars.i18n.tags.operationResultTitle, $glVars.i18n.tags.msgSuccess, 3);
                $glVars.routeCtrl.goToMyBookCollection();
            }
        });
    }
}

class MyCartView extends Component{
    static defaultProps = {
        data: null,
        onProceedCheckout: null
    };

    render(){
        let data = this.props.data;

        if(data === null){
            data = {
                details: []
            }
        }

        let subtotal = 0;

        let main = 
        <div className='my-cart-view'>
            <h3>{$glVars.i18n.tags.shoppingCart}</h3>
            {data.details.length === 0 && <div className='alert alert-primary'>{$glVars.i18n.tags.cartIsEmpty}</div>}


            {data.details.length > 0 &&
                <div className='d-flex justify-content-between flex-wrap'>
                    <div className='cart-items'>
                        {data.details.map((item, index) => {  
                            subtotal += item.amount * item.quantity;

                            let cartItem =
                                <div className='cart-item card-book' key={index}>
                                    <img alt={item.product.title} src={`${Options.getDataURL()}/${item.product.thumbnail}`}/>
                                    <div className='info'>
                                        <div className='title'>{item.product.title}</div>                                    
                                        {item.product.type === 'pp' && <div className='type'>{$glVars.i18n.tags.paperback}</div>}
                                        <div className='price'>
                                            <span className='currency'>$</span>
                                            <span className='amount'>{item.amount}</span>
                                            <span className='currency'> CAD</span>
                                        </div>
                                        {item.product.type === 'ebook' && <div className='type'>{$glVars.i18n.tags.ebook}</div>}
                                        <div className='d-flex align-items-center flex-wrap mt-2 mb-2'>
                                            <label className='m-0 mr-2'>{`${$glVars.i18n.tags.quantity}:`}</label>
                                            <InputNumber min={1} max={100} value={item.quantity} className="mr-2" size='sm' placeholder={$glVars.i18n.tags.quantity} style={{width: 50}} />
                                        </div>
                                        <Button size='sm' variant='link' onClick={() => this.onRemove(item.id)}><FontAwesomeIcon icon={faTrash}/>{` ${$glVars.i18n.tags.removeItemFromCart}`}</Button>
                                    </div>  
                                </div>;
                            return (cartItem);
                        })}
                    </div>
                    <div className='cart-checkout text-center'>
                        <div  className='card-book text-right'>
                            <span className='text-muted'>{`${$glVars.i18n.tags.subtotal} (${data.details.length} ${$glVars.i18n.tags.items}): `}</span>
                            <span className='price'>
                                <span className='currency'>$</span>
                                <span className='amount'>{subtotal}</span>
                                <span className='currency'> CAD</span>
                            </span>
                        </div>
                        <Button className='mt-4' size='sm' variant='warning' onClick={this.props.onProceedCheckout}>{` ${$glVars.i18n.tags.proceedToCheckout}`}</Button>
                    </div>
                </div>
            }
        </div>;

        return main;       
    }

    onRemove(id){
        $glVars.webApi.removeFromCart(id, (result) => {
            if(result.success){
                $glVars.signedUser.myCart = result.data;
                $glVars.feedback.showInfo($glVars.i18n.tags.operationResultTitle, $glVars.i18n.tags.msgSuccess, 3);
            }
        });
    }
}

class PaymentOrderView extends Component{
    static defaultProps = {
        data: null,
        onSeeMyCart: null,
        onApproveOrder: null
    };

    constructor(props){
        super(props);

        this.state = {tab: '0'}
    }
    render(){
        let data = this.props.data;

        let subtotal = 0;
        for(let item of data.details){
            subtotal += item.amount * item.quantity;
        }

        /*<Accordion.Item eventKey="0">
                            <Accordion.Header>Shipping Address</Accordion.Header>
                            <Accordion.Body>
                            
                            </Accordion.Body>
                        </Accordion.Item>*/
        let main = 
        <div className='payment-order-view'>
            <h2>{$glVars.i18n.tags.orderCheckout}</h2>

            <div className='d-flex justify-content-between flex-wrap'>
                <div className='payment-items'>
                    <Accordion defaultActiveKey="0">                        
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>{$glVars.i18n.tags.paymentMethod}</Accordion.Header>
                            <Accordion.Body>
                                <PaymentMethodPayPal amount={subtotal} onApproveOrder={this.props.onApproveOrder}/>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className='order-summary text-center'>
                    <div  className=''>
                        <span className='text-muted'>{`${$glVars.i18n.tags.subtotal} (${data.details.length} ${$glVars.i18n.tags.items}): `}</span>
                        <span className='price'>
                            <span className='currency'>$</span>
                            <span className='amount'>{subtotal}</span>
                            <span className='currency'> CAD</span>
                        </span>
                    </div>
                   
                    <Button className='mt-4' size='sm' variant='link' onClick={this.props.onSeeMyCart}>{$glVars.i18n.tags.returnMyCart}</Button>
                </div>
            </div>
        </div>;

        return main;
    }
}

class PaymentMethodPayPal extends Component{
    static defaultProps = {
        amount: 0,
        onApproveOrder: null
    };

    constructor(props){
        super(props);

        this.state = {
            initialOptions: {
            "client-id": Options.getPayPalClientId(),
            currency: "CAD",
            intent: "capture",
          }
        }
        /*
        Test credit card
        Card Type: Visa
        Card Number: 4214026940613036
        Expiration Date: 09/2029
        CVV: 355*/
    }

    render(){
        let main =
            <PayPalScriptProvider options={this.state.initialOptions}>
                <PayPalCheckout amount={this.props.amount} onApproveOrder={this.props.onApproveOrder}/>
            </PayPalScriptProvider>;

        return main;
    }
}

class PayPalCheckout extends Component{
    static defaultProps = {
        amount: 0,
        onApproveOrder: null
    };

    constructor(props){
        super(props);

        this.onCreateOrder = this.onCreateOrder.bind(this);
        this.onApproveOrder = this.onApproveOrder.bind(this);

        //console.log(usePayPalScriptReducer());
    }

    render(){
        let main = 
            <div>
                <PayPalButtons 
                    style={{ layout: "vertical" }}
                    createOrder={(data, actions) => this.onCreateOrder(data, actions)}
                    onApprove={(data, actions) => this.onApproveOrder(data, actions)}
                />
            </div>

        return main;
    }

    onCreateOrder(data, actions){
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: this.props.amount,
                    },
                },
            ],
        });
    }

    onApproveOrder(data, actions){
        return actions.order.capture().then((details) => {
            this.props.onApproveOrder(details);
        });
    }

}