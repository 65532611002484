import React, { Component } from 'react';
import {  Container, Nav, NavDropdown, Navbar} from 'react-bootstrap';
import { FeedbackCtrl, Loading } from '../libs/components/Components.js';
import { $glVars } from '../common/common';
import { RouteCtrl } from './MainView.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faChevronLeft, faExpand, faFont, faListOl, faPalette, faYinYang } from '@fortawesome/free-solid-svg-icons';
import { Options } from '../common/Options.js';
import Utils from '../libs/utils/Utils.js';
/*import {faSearch, faPlus} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';*/

export class BookReaderV2 extends Component
{
    static defaultProps = {
        productId: 0,
    };

    constructor(props){
        super(props);

        this.onClick = this.onClick.bind(this);
        this.getData = this.getData.bind(this);
        this.getDataResult = this.getDataResult.bind(this);
        this.onWindowResize = this.onWindowResize.bind(this);
        this.onWindowMessage = this.onWindowMessage.bind(this);
        this.onIframeLoad = this.onIframeLoad.bind(this);
        this.setLastPlayback = this.setLastPlayback.bind(this);
        this.setEditorsNotes = this.setEditorsNotes.bind(this);

        this.state = {
            data: "", 
            showMenu: false, 
            options: {
                background: 'background-creme',
                fontSize: 'font-size-medium'
            }, 
            tableContents: []
        };
        
        this.iframeRef = React.createRef();
        this.navbarRef = React.createRef();
        this.loadingRef = React.createRef();
        this.mainNavbar = null;
        this.mainFooter = null;
    }

    componentDidMount(){
        this.getData();    
        this.setMainWindow();
        this.setIframeDimensions();

        window.addEventListener("resize", this.onWindowResize);
        window.addEventListener('message', this.onWindowMessage);
    }

    componentWillUnmount(){
        document.body.style.overflow = 'auto';

        this.mainNavbar.classList.remove('hide');
        this.mainFooter.classList.remove('hide');

        window.removeEventListener("resize", this.onWindowResize);
        window.removeEventListener("message", this.onWindowMessage);
    }

    onWindowMessage(event){
        // Message received from child
        switch(event.data.type){
            case 'ready':
                this.onIframeLoad();
                break;
            default:
                break;
                // nothing
        }
    }

    setMainWindow(){
        document.body.style.overflow = 'hidden';
        
        this.mainNavbar = document.querySelector('header');
        this.mainFooter = document.querySelector('footer');

        this.mainNavbar.classList.add('hide');
        this.mainFooter.classList.add('hide');


        this.navbarRef.current.classList.add("hide");
    }

    onIframeLoad(){
        //this.iframeRef.current.contentWindow.addEventListener("click", this.onClick);
        this.iframeRef.current.contentWindow.document.body.firstChild.addEventListener("click", this.onClick);

        this.iframeRef.current.contentWindow.document.body.classList.add(this.state.options.background);
        this.iframeRef.current.contentWindow.document.body.classList.add(this.state.options.fontSize);

        this.setTableContents();
       // this.setImages();
        this.setEditorsNotes();

        this.iframeRef.current.contentWindow.onscroll = this.setLastPlayback;
        let playback = $glVars.cookies.getLastPlayback($glVars.signedUser.userId, this.props.productId, 0);
        this.iframeRef.current.contentWindow.scrollTo(0, playback);
    }

    setIframeDimensions(){
        this.iframeRef.current.width = window.innerWidth;
        this.iframeRef.current.height = window.innerHeight;
    }

    onWindowResize(){
        this.setIframeDimensions();
    }

    getData(){
        $glVars.webApi.getMyProduct(this.props.productId, this.getDataResult);
    }

    getDataResult(result){
        if(result.data === null){
            FeedbackCtrl.instance.showError($glVars.i18n.tags.appName, $glVars.i18n.tags.noBookFound);
            $glVars.routeCtrl.goToMyBookCollection();
            return;
        }

        let head = this.iframeRef.current.contentWindow.document.head;
        while (head.firstChild) {
            head.removeChild(head.lastChild);
        }
            
        let el = document.createElement("link");
        el.setAttribute('rel', 'stylesheet')
        el.setAttribute('href', `${Options.getDataURL()}common/ebook.css` )
        head.appendChild(el);

        el = document.createElement("script");
        el.setAttribute('src', `${Options.getDataURL()}/common/ebook.js` )
        head.appendChild(el);

        let ebook = result.data.productList[0].productDetail;
        ebook = ebook.replace(/__common__\//g, `${Options.getDataURL()}/common/`);

        // remove mouse right click 
        this.iframeRef.current.contentWindow.document.body.oncontextmenu = function(e){
            return false;
        }

        // safari does not accept click event on window, so we add here a root tag to patch it
        this.iframeRef.current.contentWindow.document.body.innerHTML = `<div>${ebook}</div>`;

        this.setState({data: ebook});
    }

    setTableContents(){
        let chapters = this.iframeRef.current.contentWindow.document.querySelectorAll('.headers_chapitre,.headers_chapter');

        let tableContents = [];
        for(let item of chapters){
            tableContents.push(
                {
                    text: item.innerText, 
                    onClick: () => {
                        this.iframeRef.current.contentWindow.scrollTo(0, item.offsetTop);
                    }
                });
        }

        this.setState({tableContents: tableContents});
    }

    /*setImages(){
        let imgList = this.iframeRef.current.contentWindow.document.querySelectorAll('img');

        for(let item of imgList){
            item.setAttribute('src', item.getAttribute('src').replace(/__common__\//g, `${Options.getDataURL()}/common/`));
        }
    }*/

    setLastPlayback(event){
        $glVars.cookies.setLastPlayback($glVars.signedUser.userId, this.props.productId, this.iframeRef.current.contentWindow.scrollY);
    }

    setEditorsNotes(){
        let itemList = this.iframeRef.current.contentWindow.document.querySelectorAll('._idFootnoteLink');

        for(let item of itemList){
            item.addEventListener('click', (event) => {
                event.preventDefault();

                let hash = item.hash.substr(1, item.hash.length - 1); // '#footnote-000' to footnote-000
                let el = this.iframeRef.current.contentWindow.document.getElementById(hash);
                
                if(el){
                    let pos = el.getBoundingClientRect();
                    this.iframeRef.current.contentWindow.scrollTo(0, this.iframeRef.current.contentWindow.scrollY + pos.y);
                }
            })
        }
    }

    render() {        
        let main = 
            <div className={`BookReaderV2`}>
                <Navbar ref={this.navbarRef} bg="dark" variant="dark">
                    <Container fluid>
                        <Navbar.Toggle aria-controls="menu" />
                        <Navbar.Collapse id="second-navbar-nav">
                            <Nav className="w-100" style={{justifyContent: 'space-around'}}>
                                <Nav.Link  onClick={() => RouteCtrl.instance.goToMyBookCollection()} title={$glVars.i18n.tags.back}><FontAwesomeIcon icon={faChevronLeft} /></Nav.Link>
                                <Nav.Link><FontAwesomeIcon icon={faExpand}  onClick={() => this.setOptions('fullscreen')} title={$glVars.i18n.tags.fullScreen}/></Nav.Link>
                                <NavDropdown title={<FontAwesomeIcon icon={faListOl} />} id="dropdownTableContent">
                                    {this.state.tableContents.map((item, index) => {  
                                        return (<NavDropdown.Item key={index} onClick={item.onClick}>{item.text}</NavDropdown.Item>);
                                    })}
                                </NavDropdown>
                                
                                <Nav.Link  onClick={() => this.setOptions('fontSize')} title={$glVars.i18n.tags.fontSize}><FontAwesomeIcon icon={faFont}/></Nav.Link>
                                <Nav.Link onClick={() => this.setOptions('background')} title={$glVars.i18n.tags.backgroundColor}><FontAwesomeIcon icon={faPalette} /></Nav.Link>
                            </Nav>  
                        </Navbar.Collapse>  
                    </Container>
                </Navbar>
                <iframe ref={this.iframeRef} title="Book Reader" ></iframe>

                <Loading ref={this.loadingRef}><FontAwesomeIcon icon={faYinYang} spin/></Loading>
            </div>;
//<Nav.Link><FontAwesomeIcon icon={faSearch} /></Nav.Link>
        return (main);
    }

    onClick(event){
        if(this.navbarRef.current.classList.contains('show')){
            this.navbarRef.current.classList.remove("show");
            this.navbarRef.current.classList.add("hide");
        }
        else{
            this.navbarRef.current.classList.remove("hide");
            this.navbarRef.current.classList.add("show");
        }

        this.iframeRef.current.contentWindow.postMessage({action: 'setCSS', value: this.state.options.background}, "*");
    }

    setOptions(name){
        let options = this.state.options;
        let bgOptions = ['background-white', 'background-creme', 'background-dark'];
        let fontSizeOptions = ['font-size-small', 'font-size-medium', 'font-size-large', 'font-size-extra-large'];

        if(name === 'background'){            
            let current = bgOptions.indexOf(options.background);
            options.background = (bgOptions[++current] ? bgOptions[current] : bgOptions[0]);
        }
        else if(name === 'fullscreen'){
            Utils.toggleFullScreen();
        }
        else if(name === 'fontSize'){
            let current = fontSizeOptions.indexOf(options.fontSize);
            options.fontSize = (fontSizeOptions[++current] ? fontSizeOptions[current] : fontSizeOptions[0]);
        }

        let that = this;
        this.setState({options: options}, () => {
            for(let item of bgOptions){
                that.iframeRef.current.contentWindow.document.body.classList.remove(item);
            }
            that.iframeRef.current.contentWindow.document.body.classList.add(that.state.options.background);

            for(let item of fontSizeOptions){
                that.iframeRef.current.contentWindow.document.body.classList.remove(item);
            }
            that.iframeRef.current.contentWindow.document.body.classList.add(that.state.options.fontSize);
        });
    }
}
/*
export class BookReaderV1 extends Component
{
    static defaultProps = {
        name: '',
        lang: ''
    };

    constructor(props){
        super(props);

        this.scrollTo = this.scrollTo.bind(this);
        this.onWheel = this.onWheel.bind(this);
        this.onkeyUp = this.onkeyUp.bind(this);
        this.onClick = this.onClick.bind(this);
        this.getData = this.getData.bind(this);
        this.setPages = this.setPages.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.initBreakdownPages = this.initBreakdownPages.bind(this);
        this.breakingDownPages = this.breakingDownPages.bind(this);
        this.endBreakdownPages = this.endBreakdownPages.bind(this);
        this.setOptions = this.setOptions.bind(this);

        this.state = {data: "", showMenu: false, pages: {nbPages:0, current: 1, height: 0 }, touchStart: 0, options: {background: 'background-creme'}};
        
        this.iframeRef = React.createRef();
        this.paginationRef = React.createRef();
        this.navbarRef = React.createRef();
        this.loadingRef = React.createRef();
    }

    componentDidMount(){
        this.getData();    
        this.setMainWindow();
        this.setIframe();
        this.setPages();
    }

    setMainWindow(){
        document.body.style.overflow = 'hidden';
        
        this.mainNavbar = document.getElementById('mainnavbar');
        this.mainNavbar.style.display = 'none';

        this.navbarRef.current.classList.add("hide");

        window.addEventListener("resize", this.setPages);
        window.addEventListener("keyup", this.onkeyUp);
    }

    setIframe(){
        this.iframeRef.current.width = window.innerWidth;
        this.iframeRef.current.height = window.innerHeight;
        this.iframeRef.current.contentWindow.document.firstChild.style.overflow = 'hidden';

        this.iframeRef.current.contentWindow.addEventListener("wheel", this.onWheel);
        this.iframeRef.current.contentWindow.addEventListener("keyup", this.onkeyUp);
        this.iframeRef.current.contentWindow.addEventListener("click", this.onClick);

        this.iframeRef.current.contentWindow.addEventListener('touchstart', (event) => {
            this.setState({touchStart: event.touches[0].clientX});
        });

        this.iframeRef.current.contentWindow.addEventListener('touchend', (event) => {
            if(this.state.touchStart - event.changedTouches[0].clientX > 0){
                this.setState({touchStart: 0}, this.nextPage);
            }
            else if(this.state.touchStart - event.changedTouches[0].clientX < 0){
                this.setState({touchStart: 0}, this.previousPage);
            }
        });

        this.iframeRef.current.contentWindow.document.body.classList.add(this.state.options.background);

        let el = document.createElement("link");
        el.setAttribute('rel', 'stylesheet');
        el.setAttribute('href', `${Options.backendURL}/bookstorebackend/backend/data/common/ebook.css` )
        this.iframeRef.current.contentWindow.document.head.appendChild(el);

        el = document.createElement("style");
        el.type = 'text/css';
        //el.appendChild(document.createTextNode(`.book-reader-page{height: ${this.state.pages.height}px;`));
        el.appendChild(document.createTextNode(`
        .book-reader-page{padding-left: 10px; padding-right: 10px;}
        .background-white{background-color: #FFF!important; color: #000!important;}
        .background-creme{background-color: #fefbeab0!important; color: #000!important;}
        .background-dark{		background-color: #1c1e1f!important; 		color: #FFF!important;	}`));
        
        this.iframeRef.current.contentWindow.document.head.appendChild(el);
    }

    componentWillUnmount(){
        document.body.style.overflow = 'auto';

        this.mainNavbar.style.display = 'flex';
    }

    getData(){
        let that = this;

        let callback = function(result){
            if(!result.signedIn){
                $glVars.signedUser = null;
                return;
            }

            if(result.success){
                result.data.data = result.data.data.replace(/__common__\//g, `${Options.backendURL}/bookstorebackend/backend/data/common/`);
                //result.data.data = result.data.data.replace(/resources\/image\//g, `${Options.backendURL}/bookstorebackend/backend/data/${that.props.name}/resources/image/`);

                that.setState({data: result.data.data}, that.initBreakdownPages);
            }
            else{
                FeedbackCtrl.instance.showError($glVars.i18n.tags.appName, result.msg);
            }
        }

        $glVars.webApi.getBook(this.props.name, this.props.lang, callback);
    }

    setPages(){
        this.iframeRef.current.width = window.innerWidth;
        this.iframeRef.current.height = window.innerHeight;

        let tmp = this.state.pages;
        tmp.height = parseInt(this.iframeRef.current.height, 10);
        tmp.nbPages = Math.ceil(this.iframeRef.current.contentWindow.document.body.scrollHeight / tmp.height) + 1;

        this.setState({pages: tmp});
    }

    initBreakdownPages(){
        const config = { attributes: false, childList: true, subtree: false };
        
        const observer = new MutationObserver(this.breakingDownPages);
        
        this.loadingRef.current.domRef.current.style.display = 'block';

        observer.observe(this.iframeRef.current.contentWindow.document.body, config);

        this.iframeRef.current.contentWindow.document.body.innerHTML = this.state.data;
        
        window.setTimeout(() => this.endBreakdownPages(observer), 3000);
    }

    breakingDownPages(mutationList, observer){
        let padding = 10;

        for (const mutation of mutationList) {
            if(mutation.addedNodes.length === 0){
                continue;
            }

            for(let el of mutation.addedNodes){
                let count = 0;

                if((typeof el.classList === "undefined") || !(el.classList.contains('book-reader-page'))){
                    continue;
                }
                
                // attribute data-new-page prevent to create new pages in loop
                if(el.getAttribute('data-new-page') === '1'){
                    continue;
                }

                do{
                    count++;

                    // creates a new page
                    let elHeight = Utils.getOuterHeight(el);
                    if(elHeight + padding <= this.state.pages.height){
                        el.style.minHeight = `${this.state.pages.height}px`; 
                        el.style.maxHeight = `${this.state.pages.height}px`;
                        break;
                    }

                    let newPage = el.cloneNode();
                    newPage.setAttribute("data-new-page", "1");
                    newPage.style.minHeight = `${this.state.pages.height}px`; 
                    newPage.style.maxHeight = `${this.state.pages.height}px`;
                    let newPageHeight = 0;
                    do{
                        if(el.firstElementChild === null){
                            break;
                        }
                        else{
                            let childHeight = Utils.getOuterHeight(el.firstElementChild);

                            if(newPageHeight + childHeight + padding > this.state.pages.height){
                                break;
                            }
                            else{
                                newPageHeight += Utils.getOuterHeight(el.firstElementChild);
                                newPage.appendChild(el.firstElementChild);
                            }
                        }
                    }while(true);
                    
                    el.parentNode.insertBefore(newPage, el);

                    // avoid infinite loop
                    if(count > 150){
                        break;
                    }                            
                }while(true);
            }
        }
    }

    endBreakdownPages(observer){
        let mutationList = observer.takeRecords();

        if(mutationList.length === 0){
            observer.disconnect();
            this.loadingRef.current.domRef.current.style.display = 'none';
            this.setPages();
            return;
        }
        
        mutationList = observer.takeRecords();
        this.breakingDownPages(mutationList, observer);
        window.setTimeout(() => this.endBreakdownPages(observer), 1000);
    }

    render() {        
        let main = 
            <div className={`BookReader`}>
                <Navbar ref={this.navbarRef}  bg="dark" variant="dark">
                    <Container fluid>
                        <Navbar.Toggle aria-controls="menu" />
                        
                        <Nav className="w-100" style={{justifyContent: 'space-around'}}>
                            <Nav.Link  onClick={() => RouteCtrl.instance.goToHome()} title="Revenir"><FontAwesomeIcon icon={faChevronLeft} /></Nav.Link>                            
                            <Nav.Link><FontAwesomeIcon icon={faExpand}  onClick={() => this.setOptions('fullscreen')} title="Full screen"/></Nav.Link>
                            <Nav.Link><FontAwesomeIcon icon={faListOl} /></Nav.Link>
                            <Nav.Link><FontAwesomeIcon icon={faSearch} /></Nav.Link>
                            <Nav.Link><FontAwesomeIcon icon={faFont}/></Nav.Link>
                            <Nav.Link  onClick={() => this.setOptions('background')} title="Couleur de fond"><FontAwesomeIcon icon={faPalette} /></Nav.Link>
                        </Nav>    
                    </Container>
                </Navbar>
                <iframe ref={this.iframeRef} title="Book Reader" ></iframe>

                <div ref={this.paginationRef} className='pagination hide'>
                    <Form.Range value={this.state.pages.current} min={1} max={this.state.pages.nbPages} onChange={this.onChangePage} />
                    <div className='pageNumber'>{`${this.state.pages.current}/${this.state.pages.nbPages}`}</div>
                </div>
                <Loading ref={this.loadingRef}><FontAwesomeIcon icon={faYinYang} spin/></Loading>
            </div>;

        return (main);
    }

    onClick(event){
        if(this.paginationRef.current.classList.contains('show')){
            this.paginationRef.current.classList.remove('show');
            this.paginationRef.current.classList.add('hide');

            this.navbarRef.current.classList.remove("show");
            this.navbarRef.current.classList.add("hide");
        }
        else{
            this.paginationRef.current.classList.remove('hide');
            this.paginationRef.current.classList.add('show');

            this.navbarRef.current.classList.remove("hide");
            this.navbarRef.current.classList.add("show");
        }
    }

    onChangePage(event){
        let tmp = this.state.pages;
        tmp.current = parseInt(event.target.value);
        this.setState({pages: tmp}, this.scrollTo);
    }

    onkeyUp(event){
        if(event.key === "ArrowRight"){
            this.nextPage();
        }
        else if(event.key === "ArrowLeft"){
            this.previousPage();
        }
    }

    onWheel(event){
        if(event.deltaY > 0){
            this.nextPage();
        }
        else{
            this.previousPage();
        }
    }

    previousPage(){
        let tmp = this.state.pages;
        tmp.current = Math.max(tmp.current - 1, 1);
        this.setState({pages: tmp}, this.scrollTo);    
    }

    nextPage(){
        let tmp = this.state.pages;
        tmp.current = Math.min(tmp.current + 1, tmp.nbPages);        
        this.setState({pages: tmp}, this.scrollTo);
    }

    scrollTo(){
        if(this.iframeRef.current){
            this.iframeRef.current.contentWindow.scrollTo(0, (this.state.pages.current - 1) * this.state.pages.height);
        }
    }

    setOptions(name){
        let options = this.state.options;
        let list = ['background-white', 'background-creme', 'background-dark'];

        if(name === 'background'){            
            let current = list.indexOf(options.background);
            options.background = (list[++current] ? list[current] : list[0]);
        }
        else if(name === 'fullscreen'){
            Utils.toggleFullScreen();
            this.initBreakdownPages();
        }

        let that = this;
        this.setState({options: options}, () => {
            for(let item of list){
                that.iframeRef.current.contentWindow.document.body.classList.remove(item);
            }
            that.iframeRef.current.contentWindow.document.body.classList.add(that.state.options.background);
        });
    }
}
*/