import {WebApi} from '../libs/utils/Utils';
import { RouteCtrl } from '../views/MainView';
import { Options } from './Options';
import { $glVars } from './common';

export class AppWebApi extends WebApi
{
    static FileManagement = {
        maxFileSize: 25, // MB
        pathTo: {
            project: "/{noProject}/",
            toDo: "/{noProject}/{todo}/{no}/",
            followUp: "/{noProject}/{todo}/{noTask}/{no}/"
        }
    };

    constructor(){
        super(Options.getGateway());
                
        this.signIn = this.signIn.bind(this);
        this.onPublicPostResult = this.onPublicPostResult.bind(this);
        this.onPrivatePostResult = this.onPrivatePostResult.bind(this);

        this.http.useCORS = true;
        this.sid = 0;
        this.observers = [];
        this.http.timeout = 30000; // 30 secs
    }

    addObserver(id, update, observables){
        this.observers.push({id:id, update:update, observables: observables});
    }

    removeObserver(id){
        this.observers.nxRemoveItem("id", id);
    }

    notifyObservers(observable){
        for(let o of this.observers){
            if(o.observables.includes(observable)){
                o.update();
            }
        }
    }

    onPublicPostResult(result, onResult){
        if(!result.success){
            $glVars.feedback.showError(result.msg);
        }
        else{
            onResult(result);
        }  
    }

    onPrivatePostResult(result, onResult, service){
        if(!result.success){
            $glVars.feedback.showError(result.msg);
        }
        else if(!result.signedIn){
            RouteCtrl.instance.goToSignIn();
        }
        else{
            onResult(result);
            this.notifyObservers(service);
        }  
    }
    
    recoverSession(sid, onSuccess){
        let data = {sid: sid || '', service: "recoverSession"};

        let that = this;
        let callback = function(result){
            that.sid = (result.success && result.signedIn ? result.data.sid : '');
            onSuccess(result);
        }

        this.post(this.gateway, data, callback);
    }

    signIn(login, password, onSuccess){
        let data = {sid: '', login: login || "", password: password || "", service: "signIn"};

        let that = this;
        let callback = function(result){
            that.sid = (result.success && result.signedIn ? result.data.sid : '');
            onSuccess(result);
        }

        this.post(this.gateway, data, callback);
    }

    signOut(onSuccess){
        let data = {sid: this.sid || '', service: "signOut"};
        this.post(this.gateway, data, onSuccess);
    }   

    createAccount(data, onResult){
        let options = {sid: this.sid, public: 1, data: data, service: "createAccount"};
        this.post(this.gateway, options, (result) => this.onPublicPostResult(result, onResult));
    }

    recoverPassword(data, onResult){
        let options = {sid: this.sid, public: 1, data: data, service: "recoverPassword"};
        this.post(this.gateway, options, (result) => this.onPublicPostResult(result, onResult));
    }

    setNewPassword(data, onResult){
        let options = {sid: this.sid, public: 1, data: data, service: "setNewPassword"};
        this.post(this.gateway, options, (result) => this.onPublicPostResult(result, onResult));
    }

    validateToken(token, onResult){
        let options = {sid: this.sid, public: 1, token: token, service: "validateToken"};
        this.post(this.gateway, options, (result) => this.onPublicPostResult(result, onResult));
    }
    
    getAvailableProducts(onResult){
        let options = {sid: this.sid, public: 1, service: "getAvailableProducts"};
        this.post(this.gateway, options, (result) => this.onPublicPostResult(result, onResult));
    }

    getBookCollection(onSuccess){
        let options = {sid: this.sid, public: 1, service: "getBookCollection"};
        this.post(this.gateway, options, onSuccess);
    }

    getMyBookCollection(onResult){
        let options = {sid: this.sid, service: "getMyBookCollection"};
        this.post(this.gateway, options, (result) => this.onPrivatePostResult(result, onResult));
    }

    getMyProduct(productId, onResult){
        let options = {sid: this.sid, productId:productId, service: "getMyProduct"};
        this.post(this.gateway, options, (result) => this.onPrivatePostResult(result, onResult));
    }

    addToCart(productId, onResult){
        let options = {sid: this.sid, productId: productId, service: "addToCart"};
        this.post(this.gateway, options, (result) => this.onPrivatePostResult(result, onResult));
    }

    getMyCart(onResult){
        let options = {sid: this.sid, service: "getMyCart"};

        this.post(this.gateway, options, (result) => this.onPrivatePostResult(result, onResult));
    }

    removeFromCart(id, onResult){
        let options = {sid: this.sid, id: id, service: "removeFromCart"};
        this.post(this.gateway, options, (result) => this.onPrivatePostResult(result, onResult, 'removeFromCart'));
    }

    closeOrder(orderId, paypalDetails, onResult){
        let options = {sid: this.sid, orderId: orderId, paypalDetails: paypalDetails, service: "closeOrder"};

        this.post(this.gateway, options, (result) => this.onPrivatePostResult(result, onResult));
    }

    searchOnBooks(limit, offset, searchParam, onResult){
        let options = {sid: this.sid, limit: limit, offset: offset, searchParam: searchParam, service: "searchOnBooks"};

        this.post(this.gateway, options, (result) => this.onPrivatePostResult(result, onResult));
    }
};
